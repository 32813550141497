import { Helmet } from "react-helmet-async";
import { UtagData } from "../types/utag";

export type AdobeDTMProps = {
  embed_url: string;
  utag_data?: UtagData;
};

export const AdobeDTM = (props: AdobeDTMProps) => {
  const { utag_data, embed_url } = props;

  window.utag_data = {
    ...utag_data,
  }

  return (
    <>
      <Helmet>
        <script type="text/javascript" src={embed_url} />
      </Helmet>
    </>
  )
}


