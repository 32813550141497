import { gql } from '@apollo/client';

export const listNewestDatesQuery = gql(`
  query listNewestDates($maxDays: Int) {
    newestDates(maxDays: $maxDays) {
      levelid
      name
      title
      date
      correctstats
      questions {
        name
        question
        answer1
        answer1stats
        answer2
        answer2stats
        answer3
        answer3stats
        answer4
        answer4stats
      }
    }
  }
`);
