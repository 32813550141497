export const QuoteStyles = {
  ENT_HTML_QUOTE_NONE: 0,
  ENT_HTML_QUOTE_SINGLE: 1,
  ENT_HTML_QUOTE_DOUBLE: 2,
  ENT_HTML_IGNORE_ERRORS: 4,
  ENT_NOQUOTES: 0, // ENT_HTML_QUOTE_NONE
  ENT_COMPAT: 2, // ENT_HTML_QUOTE_DOUBLE
  ENT_QUOTES: 3, // ENT_HTML_QUOTE_DOUBLE | ENT_HTML_QUOTE_SINGLE
  ENT_IGNORE: 4, // ENT_HTML_IGNORE_ERRORS
} as const;

export type QuoteStyle = keyof typeof QuoteStyles;
export type QuoteStyleInput = number | number[] | QuoteStyle | QuoteStyle[];

const asArray = <T>(input?: T | T[]): T[] => {
  if (typeof input === 'undefined') {
    return [];
  }

  return Array.isArray(input) ? input : [input];
}

/**
 * discuss at: http://phpjs.org/functions/htmlspecialchars_decode/
 * original by: Mirek Slugen
 * improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
 * bugfixed by: Mateusz "loonquawl" Zalega
 * bugfixed by: Onno Marsman
 * bugfixed by: Brett Zamir (http://brett-zamir.me)
 * bugfixed by: Brett Zamir (http://brett-zamir.me)
 * input by: ReverseSyntax
 * input by: Slawomir Kaniecki
 * input by: Scott Cariss
 * input by: Francois
 * input by: Ratheous
 * input by: Mailfaker (http://www.weedem.fr/)
 * revised by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
 * reimplemented by: Brett Zamir (http://brett-zamir.me)
 * reimplemented by: Kalle Jokinen
 * example 1: htmlspecialchars_decode("<p>this -&gt; &quot;</p>", 'ENT_NOQUOTES');
 * returns 1: '<p>this -> &quot;</p>'
 * example 2: htmlspecialchars_decode("&amp;quot;");
 * returns 2: '&quot;'
 * 
 * @param {String} input
 * @param {QuoteStyle} quoteStyle
 */
export const htmlspecialcharsDecode = (
  input: string,
  quoteStyle: QuoteStyleInput = 'ENT_HTML_QUOTE_DOUBLE',
): string => {
  let flags = 0;
  let noquotes = false;
  let style = asArray(quoteStyle).map(it => typeof it === 'number' ? it : QuoteStyles[it]);

  for (let i = 0; i < style.length; i++) {
    flags = flags | style[i];

    if (style[i] === 0) {
      noquotes = true;
    }
  }

  input = input.toString().replace(/&lt;/g, '<').replace(/&gt;/g, '>');

  if (flags & QuoteStyles.ENT_HTML_QUOTE_SINGLE) {
      input = input.replace(/&#0*39;/g, "'"); // PHP doesn't currently escape if more than one 0, but it should
  }

  if (!noquotes) {
      input = input.replace(/&quot;/g, '"');
  }

  return input.replace(/&amp;/g, '&');
}
