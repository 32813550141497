import { useNavigate } from 'react-router-dom';
import { useGameContext } from '../hooks/use-game-context';
import { useEffect } from 'react';
import { ReactComponent as ArrowRight } from '../img/arrow-right.svg';
import { GameQuestion } from '../types/game';
import { QUESTION_ANSWER_MISSING, countMissingAnswers, isAnswerSelected } from '../utils/quiz-helpers';
import { default as classNames } from 'classnames';
import { useUserContext } from '../hooks/use-user-context';
import { GameHistory } from '../types/game-history';
import { useSaveDateAnswersMutation } from '../hooks/use-save-date-answers-mutation';

export const QuizPage = () => {
  const { game, answers, playable, setAnswers, setPlayable } = useGameContext();
  const { user, setUser } = useUserContext();
  
  const { saveDateAnswers, saveDateError } = useSaveDateAnswersMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!game || !answers) {
      navigate('/');
    }
  }, [game, answers, navigate]);

  const missingAnswers = countMissingAnswers(answers);
  const hasMissingAnswers = (missingAnswers > 0);

  const answerQuestion = (questionId: number, answerId: number) => {
    if (playable) {
      const clearAnswer = isAnswerSelected(answers, questionId, answerId);
      setAnswers({ ...answers, [questionId]: clearAnswer ? QUESTION_ANSWER_MISSING : answerId });
    }
  }

  const submitAnswers = async () => {
    if (hasMissingAnswers || !playable || !game) {
      return;
    }

    const historyEntry: GameHistory = {
      level: game.levelid,
      answers,
    }

    const updatedHistory = user.history
      .filter(({ level }) => level !== game.levelid)
      .concat([ historyEntry ]);

    const rawAnswers = Object.values(answers);
    const saveResponse = await saveDateAnswers({ levelid: game.levelid, answers: rawAnswers });

    if (saveResponse.data && !saveDateError) {
      setUser({ ...user, history: updatedHistory });
      setPlayable(false);
      navigate('/end');
    }
  };

  const submitText = (hasMissingAnswers)
    ? `Vielä ${missingAnswers} vastaamatta`
    : 'Katso tuloksesi!'

  const renderQuestion = (entry: GameQuestion, questionId: number) => {
    const { question, shuffledAnswers } = entry;

    return (
      <div className={classNames("question-block", { 'unanswered': isAnswerSelected(answers, questionId, QUESTION_ANSWER_MISSING)})} key={questionId}>
        <div className="question-no">{entry.number}</div>
        <div className="question">
            <p className="question-inner">
              {question}
            </p>
        </div>
        <ul className="answers">
          {
            shuffledAnswers.map(({ answer, answerId }, idx) => {
              const answerNo = idx + 1;
              const answerClass = `answer${answerNo}`;

              return (
                <li className={classNames("button", "answer", answerClass, { 'st-selected' : isAnswerSelected(answers, questionId, answerId)})} key={answerNo}>
                  <button className="button-inner answer-inner" onClick={() => answerQuestion(questionId, answerId)}>
                    {answer}
                  </button>
                </li>
              );
            })
          }
        </ul>
    </div>
    );
  }

  return (
    <div className="view view-game">
      <div className="event-start">
        <div className="question-blocks" id="question-blocks">
          { game?.questions.map((question, questionId) => renderQuestion(question, questionId))}
          
          <div className="submit-block">
            <div className={classNames("button", "button-submitanswers", { "button-disabled": hasMissingAnswers })}>
              <button className="button-inner" id="submitAnswers" title="Tarkista vastaukset" onClick={() => submitAnswers()}>
                <span id="submitAnswers-text">{submitText}</span>
                <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};