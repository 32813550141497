import { useState, useEffect, Dispatch } from "react";

export const useLocalStorage = <T>(key: string, initialValue: T): [T, Dispatch<React.SetStateAction<T>>] => {
  let storedValue: T | null = null;

  try {
    const storedJson = localStorage.getItem(key);

    if (storedJson !== null) {
      storedValue = JSON.parse(storedJson);
    }
  } catch (err) {
    localStorage.removeItem(key);
  }

  const [value, setValue] = useState<T>(storedValue ?? initialValue);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}