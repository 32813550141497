import { useAdStateContext } from '../hooks/use-ad-state-context';
import { default as classNames } from 'classnames';

export const Header = () => {
  const { adsState } = useAdStateContext();
  const adsVisible = (adsState !== 'ENDED');

  return (
    <header id="header">
      <div className={classNames("header-view", { 'st__hidden': adsVisible })}>
      </div>
    </header>
  );
}