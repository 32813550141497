import { ReactNode, useState } from 'react';
import { AdStateContext } from '../contexts/ad-state-context';
import { RuutuPlayerBasicState } from '../components/ruutu-player';

export type AdStateContextProviderProps = {
  children: ReactNode;
}

export const AdStateContextProvider = ({ children }: AdStateContextProviderProps) => {
  const [adsState, setAdsState] = useState<RuutuPlayerBasicState>('ENDED');

  const value = {
    adsState,
    setAdsState,
  };

  return (
    <AdStateContext.Provider value={value}>
      {children}
    </AdStateContext.Provider>
  );
};