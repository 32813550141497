import { gql } from '@apollo/client';

export const saveDateAnswersQuery = gql(`
  mutation saveDateAnswers($input: IS10KysymystaDateAnswerInput) {
    saveDateAnswers(input: $input) {
      levelid
      correctstats
      questions {
        name
        answer1stats
        answer2stats
        answer3stats
        answer4stats
      }
    }
  }
`);
