import { default as moment } from 'moment';
import { useEffect } from "react";
import { useGameContext } from "../hooks/use-game-context";
import { useNavigate } from "react-router-dom";
import { ReactComponent as WhatsAppLogo } from '../img/whatsapp.svg';
import { ReactComponent as FacebookLogo } from '../img/facebook.svg';
import { ReactComponent as TwitterLogo } from '../img/twitter.svg';
import { ReactComponent as ArrowLeft } from '../img/arrow-left.svg';
import { calcAnswerStats, countCorrectAnswers, getNextQuizDay } from '../utils/quiz-helpers';
import { useAdStateContext } from '../hooks/use-ad-state-context';
import { AdPlayer } from '../components/ad-player';
import { QuestionResult } from '../components/question-result';
import { default as classNames } from 'classnames';
import { shareFacebook, shareTwitter, shareWhatsapp } from '../utils/share-results';
import { SharedResult } from '../types/shared-result';

export const EndPage = () => {
  const navigate = useNavigate();
  const { adsState } = useAdStateContext();
  const { game, answers } = useGameContext();
  
  useEffect(() => {
    if (!game) {
      navigate('/');
    }
  }, [game, navigate]);

  if (!game) {
    return <>Loading...</>;
  }

  const today = moment();
  const score = countCorrectAnswers(answers);

  const { date, questions, correctstats } = game;
  const formattedDate = date.format('DD.MM.yyyy');

  const { howManyPlayed, worsePercent } = calcAnswerStats(answers, correctstats);
  const resultToShare: SharedResult = {
    date: formattedDate,
    howManyAreWorse: worsePercent,
    howManyPlayed,
    score,
  }

  const backToMain = () => {
    navigate('/');
  }

  const adsVisible = (adsState !== 'ENDED');
  const adsPaused = (adsState === 'PAUSED');

  return (
    <>
      <AdPlayer />
      <div id="results">
        <div className="view view-end event-updatedStats">
          <div id="end-stats" className={classNames("end-stats", { 'st__hidden': adsVisible })} >
            <h2>
              Tuloksesi - <span className="date">{formattedDate}</span>
            </h2>

            <div className="percent-bar-container">
              <div className="percent-bar">
                <div
                  id="percent-right"
                  className="percent-right"
                  style={{ width: `${score}0%` }}
                >
                  {score} oikein
                </div>
                <div className="percent-rest"></div>
              </div>
            </div>
            {(howManyPlayed >= 5)
              ? (worsePercent > 33)
                ? <div className="percentile-text">
                    Tiesit paremmin kuin
                    <div className="percentile-circle">
                      <div className="percentile-circle-fill" id="percent-worse" style={{ height: `${worsePercent}%` }}></div>
                      <div className="percentile-circle-text">{worsePercent}%</div>
                    </div>
                  </div>
                : <div className="percentile-text">
                    <div className="percentile-circle">
                      <div className="percentile-circle-fill percentile-circle-fill-red" id="percent-worse" style={{ height: `${100 - worsePercent}%` }}></div>
                      <div className="percentile-circle-text">{100 - worsePercent}%</div>
                    </div>
                    tiesi paremmin.
                  </div>
              : null
            }
          </div>
          <div id="end-actions" className={classNames("end-actions", { 'st__hidden': adsVisible })}>
            <h2 className="share-title">Jaa tuloksesi</h2>
            <div className="share">
              <button
                type="button"
                className="button button-share button-share-whatsapp"
                onClick={() => shareWhatsapp(resultToShare)}
              >
                <WhatsAppLogo />
              </button>
              <button
                type="button"
                className="button button-share button-share-facebook"
                onClick={() => shareFacebook(resultToShare)}
              >
                <FacebookLogo />
              </button>
              <button
                type="button"
                className="button button-share button-share-twitter"
                onClick={() => shareTwitter(resultToShare)}
              >
                <TwitterLogo />
              </button>
            </div>

            <div className="button button-newgame">
              <button className="button-inner" onClick={() => backToMain()}>
                <ArrowLeft />
                Palaa alkuun
              </button>
            </div>
            <div className="button button-alypaa">
              <a
                className="button-inner"
                href="http://alypaa.com/?utm_source=is10kysymysta&amp;utm_medium=is10kysymysta&amp;utm_campaign=is10kysymysta"
                target="_blank"
                rel="noopener noreferrer"
              >
                Pelaa visoja eri aiheista Älypäässä
              </a>
            </div>
          </div>
          <div className={classNames("question-blocks", { 'loop': adsVisible, 'showall': !adsVisible, 'st__hidden': adsVisible && adsPaused })} id="items">
            <h2 id="questions-title" className={classNames({ 'st__hidden': adsVisible })}>
              Näin vastasit
            </h2>
            {
              questions.map((it, idx) => <QuestionResult question={it} questionId={idx} key={idx} />)
            }
          </div>
          <p id="reminder" className={classNames("reminder-text", { 'st__hidden': adsVisible })}>
            Uudet kysymykset päivän luetuimmista uutisista taas {getNextQuizDay(today)}.
            Tervetuloa uudelleen!
          </p>

          <div id="button-newgame" className={classNames("button", "button-newgame", { 'st__hidden': adsVisible })}>
            <button className="button-inner" onClick={() => backToMain()}>
              <ArrowLeft />
              Palaa alkuun
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
