export type LoadingFailedProps = {
  error?: Error;
}

export const LoadingFailed = (props: LoadingFailedProps) => {
  return (
    <p className="reminder-text">
      Pahoittelemme, mutta kysymysten lataus epäonnistui :( Kokeile myöhemmin uudelleen.
    </p>
  );
}
