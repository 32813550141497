import { chain, countBy } from 'lodash';
import { Answers } from '../types/answers';
import { Moment } from 'moment';
import { GameQuestion } from '../types/game';

export const QUESTION_ANSWER_CORRECT = 0;
export const QUESTION_ANSWER_MISSING = -1;

const countAnswersByAnswerId = (answers: Answers, answerId: number) => {
  return countBy(answers, (answer => answer))[answerId] || 0; 
}

export const countCorrectAnswers = (answers: Answers) => {
  return countAnswersByAnswerId(answers, QUESTION_ANSWER_CORRECT);
}

export const countMissingAnswers = (answers: Answers) => {
  return countAnswersByAnswerId(answers, QUESTION_ANSWER_MISSING);
}

export const isAnswerSelected = (answers: Answers, questionId: number, answerId: number): boolean => {
  return (answers[questionId] === answerId);
}

export const createEmptyAnswers = () => chain(Array.from(Array(10).keys()))
  .keyBy()
  .mapValues(() => QUESTION_ANSWER_MISSING)
  .value();

export const getNextQuizDay = (today: Moment) => {
  return (today.weekday() === 6)
    ? 'maanantaina'
    : 'huomenna';
}

export const calcAnswerStats = (answers: Answers, correctstats: number[]) => {
  // Count how many have played.
  const howManyPlayed = correctstats.reduce(
    (accum, value) => accum + value,
    0,
  );

  // Count how many are worse.
  // TODO: Calculated differently than before, make sure this is correct
  const howManyAreWorse = correctstats.reduce(
    (accum, value, idx) => {
      if (answers[idx] === QUESTION_ANSWER_CORRECT) {
        accum += value;
      }

      return accum;
    },
    0,
  );

  const worsePercent = (howManyPlayed > 0)
    ? Math.ceil((howManyAreWorse / howManyPlayed) * 100)
    : 0;

  return { howManyPlayed, howManyAreWorse, worsePercent};
}

export const calcQuestionStats = (question: GameQuestion) => {
  const { answer1, answer2, answer3, answer4 } = question;
  const totalStats = (answer1.stats + answer2.stats + answer3.stats + answer4.stats);

  // Must have been answered min 5 times to calculate percentage.
  const rightPercent = (totalStats >= 5)
    ? Math.ceil((answer1.stats / totalStats) * 100)
    : 0;

  return { totalStats, rightPercent };
}