import { ReactNode } from 'react';
import { useLocalStorage } from '../hooks/use-local-storage';
import { UserContext, defaultUser } from '../contexts/user-context';
import { User } from '../types/user';

export type UserContextProviderProps = {
  children: ReactNode;
}

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [user, setUser] = useLocalStorage<User>('KEY_USER', defaultUser);
  const value = { user, setUser };

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};