import { RuutuPlayer, RuutuPlayerAPI, RuutuPlayerError, RuutuPlayerProps, isRuutuPlayerBasicState } from "./ruutu-player";
import { useAdStateContext } from "../hooks/use-ad-state-context";
import { useCallback, useEffect } from "react";
import { default as Settings } from '../config/settings';
import { default as classNames } from 'classnames';

export const AdPlayer = () => {
  const { adsState, setAdsState } = useAdStateContext();

  const ruutuplayerCallback = useCallback((statusOrErrMsg: boolean | string | RuutuPlayerError) => {
    setAdsState('ENDED');
    //googleAnalyticsTrack(['pageview', `/finalresults/${game?.date}`])
  }, [setAdsState]);
  
  const onPlayerApi = useCallback((api: RuutuPlayerAPI) => {
    api.onEvent((event) => {
      if (isRuutuPlayerBasicState(event.state)) {
        setAdsState(event.state);
      }
    });
  }, [setAdsState]);

  useEffect(() => {
    setAdsState('LOADING');
  }, [setAdsState]);

  const adsVisible = adsState !== 'ENDED';
  const playerProps: RuutuPlayerProps = {
    adsOnlyCallback: ruutuplayerCallback,
    errorCallback: ruutuplayerCallback,
    onApi: onPlayerApi,
    mediaUrl: Settings.MEDIA_URL,
    autoplay: true,
    responsive: true,
    pausePlaybackOnAdClickThrough: false,
    ns_site: '10kysymysta-fi',
    fw_site: '10kysymysta',
    appLink: {
      android: false,
      windowsphone: false,
      ios: false
    },
    themeColor: '#000000'
  }

  return (
    <div id="jsAD" className={classNames("banner-slot", "banner-slot-content", "banner-slot-preroll", { 'st__hidden': !adsVisible })}>
      <p className="banner-slot-preroll-info">Katso mainos, jonka jälkeen pääset tuloksiin!</p>
      <div className="banner-slot-preroll-content">
        <RuutuPlayer { ...playerProps } />
      </div>
    </div>
  );
}
