import { LoadingFailed } from '../components/loading-failed';
import { LoadingSpinner } from '../components/loading-spinner';
import { QuizMenu } from '../components/quiz-menu';
import { useQueryResultContext } from '../hooks/use-query-result';
import { IS10KysymystaNewestDatesOutput } from '../types/is10kysymysta-types';

export const MainPage = () => {
  const { loading, error, data } = useQueryResultContext<IS10KysymystaNewestDatesOutput>();

  return (
    <div id="day-menu">
      <div className="view view-home">
        <div className="question-block">
          {
            (loading || !data)
              ? <LoadingSpinner />
              : error
                ? <LoadingFailed error={error} />
                : <QuizMenu newestDates={data.newestDates} />
          }
        </div>
      </div>
    </div>
  );
};