import { useAdStateContext } from "../hooks/use-ad-state-context";
import { GameQuestion } from "../types/game";
import { FadeWrapper } from "./fade-wrapper";
import { QUESTION_ANSWER_CORRECT, calcQuestionStats, isAnswerSelected } from "../utils/quiz-helpers";
import { default as classNames } from 'classnames';
import { useGameContext } from "../hooks/use-game-context";

export type QuestionResultProps = {
  question: GameQuestion;
  questionId: number;
}

export const QuestionResult = (props: QuestionResultProps) => {
  const { question, questionId } = props;
  const { shuffledAnswers } = question;
  const { rightPercent } = calcQuestionStats(question);
  const { answers } = useGameContext();
  const { adsState } = useAdStateContext();

  const adsVisible = (adsState !== 'ENDED');

  return (
    <FadeWrapper
      enabled={adsVisible}
      startDelay={questionId * 6000 + 1000}
      repeatAfter={60000}
      key={questionId}
      visibleClassName={'st__visible'}
      opaqueClassName={'opacity-1'}
    >
      <div className={`question-block unanswered question-block-${questionId}`}>
        <div className="question-no">{question.number}</div>
        <div className="question">
          <p className="question-inner">
            {question.question}
          </p>
        </div>
        <ul className="answers">
          {
            shuffledAnswers.map(({ answer, answerId }, idx) => {
              const answerNo = idx + 1;
              const answerClass = `answer${answerNo}`;
              const isSelected = isAnswerSelected(answers, questionId, answerId);
              const isCorrect = answerId === QUESTION_ANSWER_CORRECT;
              
              return (
                <li className={classNames("button", "answer", answerClass, { 'st-right': isCorrect, 'st-wrong': !isCorrect && isSelected })} key={answerId}>
                  <span className="button-inner answer-inner">
                    {answer}
                  </span>
                </li>
              );
            })
          }
        </ul>
        {
          (rightPercent > 0)
            ? <div className="percent-bar-container">
                <p>Näin hyvin muut tiesivät:</p>
                <div className="percent-bar">
                  <div className="percent-right" style={{ width: `${rightPercent}%` }}>{rightPercent}%</div>
                </div>
              </div>    
            : null
        }
      </div>
    </FadeWrapper>
  );
};