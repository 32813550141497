import { createRef, memo, useEffect } from "react";

export const RuutuPlayerBasicStates = ['LOADING', 'PLAYING', 'SEEKING', 'PAUSED', 'ENDED'] as const;
export type RuutuPlayerBasicState = typeof RuutuPlayerBasicStates[number];

export const RuutuPlayerChapterStates = ['CHAPTERS'] as const;
export type RuutuPlayerChapterState = typeof RuutuPlayerChapterStates[number];

export const RuutuPlayerPIPButtonClickStates = ['MINIPLAYER_CLICK'] as const;
export type RuutuPlayerPIPButtonClickState = typeof RuutuPlayerPIPButtonClickStates[number];

export const RuutuPlayerSpecialEventStates = ['POSTROLL_STARTED', 'TOO_LONG_CONTINUOUS_WATCHING', 'TOO_MANY_SIMULTANEOUS_USERS', 'CONTENT_CONSUMPTION_LIMIT_EXCEEDED', 'EXPANDED', 'COLLAPSED', 'CLOSE', 'ENTER_FULLSCREEN', 'EXIT_FULLSCREEN'] as const;
export type RuutuPlayerSpecialEventState = typeof RuutuPlayerSpecialEventStates[number];

export const RuutuPlayerAdbreakTypes = ['preroll', 'sponsroll', 'midroll', 'postroll'] as const;
export type RuutuPlayerAdbreakType = typeof RuutuPlayerAdbreakTypes[number];

export const isRuutuPlayerBasicState = (input: unknown): input is RuutuPlayerBasicState => {
  return RuutuPlayerBasicStates.includes(input as RuutuPlayerBasicState);
}

export const isRuutuPlayerChapterState = (input: unknown): input is RuutuPlayerChapterState => {
  return RuutuPlayerChapterStates.includes(input as RuutuPlayerChapterState);
}

export const isRuutuPlayerPIPButtonClickState = (input: unknown): input is RuutuPlayerPIPButtonClickState => {
  return RuutuPlayerPIPButtonClickStates.includes(input as RuutuPlayerPIPButtonClickState);
}

export const isRuutuPlayerSpecialEventState = (input: unknown): input is RuutuPlayerSpecialEventState => {
  return RuutuPlayerSpecialEventStates.includes(input as RuutuPlayerSpecialEventState);
}

export const isRuutuPlayerAdbreakType = (input: unknown): input is RuutuPlayerAdbreakType => {
  return RuutuPlayerAdbreakTypes.includes(input as RuutuPlayerAdbreakType);
}

export type RuutuPlayerAdbreak = {
  count: number;
  index: number;
  type: RuutuPlayerAdbreakType;
}

export type RuutuPlayerChapter = {
  duration: number;
  startTime: number;
}

/**
 * Example event:
 * {
 *   currentTime: 98.79,
 *   duration: 102.052,
 *   isLive: false,
 *   mediaId: "905431",
 *   state: "PLAYING"
 * }
 */
export type RuutuPlayerBasicEvent = {
  /**
   * NID
   */
  mediaId: string;

  mediaTitle: string;

  /**
   * Current media is playing live content.
   */
  isLive: boolean;

  /**
   * Current play-position in seconds. 
   */
  currentTime: number;

  /**
   * Current media's duration in seconds.
   */
  duration: number;

  adBreak: RuutuPlayerAdbreak;
  state: RuutuPlayerBasicState;
}

/**
 * Chapters event can be used to get book chapters
 */
export type RuutuPlayerChapterEvent = {
  /**
   * Possible values: 'CHAPTERS'
   */
  state: RuutuPlayerChapterState;

  /**
   * Array of objects in a form { duration: 98.79, startTime: 0}
   */
  chapters: RuutuPlayerChapter[];
}

/**
 * Event is fired when miniplayerButton is clicked. Contains the same data as basic events above with state
 */
export type RuutuPlayerPIPButtonClickEvent = Omit<RuutuPlayerBasicEvent, 'state'> & {
  state: RuutuPlayerPIPButtonClickState;
}

/**
 * Some special events from the same onEvent callback. This
 * events do not contain any special parameters.
 */
export type RuutuPlayerSpecialEvent = {
  /**
   * 'POSTROLL_STARTED': This event is dispatched when postrollMedia is started playing
  *  'TOO_LONG_CONTINUOUS_WATCHING': This event is dispatched when time limit of continuous listening is reached. 3 hours.
  *  'TOO_MANY_SIMULTANEOUS_USERS': This event is dispatched based on device api response
  *  'CONTENT_CONSUMPTION_LIMIT_EXCEEDED' This event is dispatched when device api returns corresponding error
  *  'EXPANDED': This event is dispatched when expand button is clicked.
  *  'COLLAPSED': This event is dispatched when collapse button is clicked.
  *  'CLOSE': This event is dispatched when close player button is clicked.
  *  'ENTER_FULLSCREEN': This event is dispatched when enter fullscreen button is clicked.
  *  'EXIT_FULLSCREEN': This event is dispatched when exit fullscreen button is clicked.
   */
  state: RuutuPlayerSpecialEventState;

  /**
   * Some special errors have a message returned from the api  
   */
  message: string;
}

export type RuutuPlayerEvent = RuutuPlayerBasicEvent | RuutuPlayerChapterEvent | RuutuPlayerPIPButtonClickEvent | RuutuPlayerSpecialEvent;

export type RuutuPlayerAPI = {
  /**
   * Dynamic Supla theme can be configured "runtime". See Supla theme section below for instructions for theme Object. 
   */
  configureTheme: (themeObject: object) => void;
  
  /**
   * Stops the playback. play(): Starts the playback. playMuted(): Starts the playback muted. Also shows "muted" overlay as in autoplay='muted' case. 
   */
  pause: () => void; 
  
  /**
   * returns current volume as number between 0 and 1.
   */
  getVolume: () => number;
  
  /**
   * Sets current volume. Valid parameter is number between 0 and 1. 
   */
  setVolume: (volume: number) => void;
  

  /**
   * Returns true if muted, false if not.
   */
  isMuted: () => boolean;
  
  /**
   * Returns true if playing, false if not.
   */
  isPlaying: () => boolean;
  
  /**
   * Sets player muted or not.
   */
  mute: (muted: boolean) => void;
  
  /**
   * Seeks to position.
   */
  seekTo: (position: number) => void;
  
  /**
   * Removes the player from DOM.
   */
  remove: () => void;
  
  /**
   * Adds an event listener (callback) that is called when player's state changes. 
   */
  onEvent: (callback: (event: RuutuPlayerEvent) => void) => void;
};

export type RuutuPlayerAppLink = {
  android: boolean | 'require';
  windowsphone: boolean | 'require';
  ios: boolean | 'require';
}

export type RuutuPlayerError = {
  type: string;
  message: string;
}

type RuutuPlayerInternalProps = {
  /**
   * Set adId for analytics purposes. If parameter is not set it will be
   * fetched from the authtoken or identify api
   */
  adId?: string;

  /**
   * Function to call after prerolls have been played. When this is set
   * the player will stop after prerolls.
   * AdsOnlyCallback receives a parameter which is
   * 'true' when ads where shown to the user
   * 'false' there were no ads to be shown. Either no ads where
   * booked for the content or the user has watched the same ads
   * too many times and therefore no ads were served to him.
   * 'blocked' no ads can be shown because the user uses ad
   * blocker.
   * When adsOnlyCallback is set the player doesn't make any
   * requests to the Gatling API
   */
  adsOnlyCallback?: (adsShown: boolean | 'blocked') => void;

  /**
   * Specifies device universal id for advertisement.
   */
  advertisementId?: string;

  /**
   * Default value if not set: false
   * If appLink parameter is enabled, the player will show a link in the
   * top of the player which will open the video in the native ruutu
   * application. If the application is not installed the link will open the
   * ruutu page in the market place.
   * For android the browser doesn't know how to redirect the user to
   * the market place automatically if the application is not installed.
   * Therefore on android the player will show an additional store
   * button which opens the store for the user.
   * appLink: {
   *   android: false,
   *   windowsphone: "require",
   *   ios: false
   * }
   * This example configuration would disable the feature for android
   * and ios users, and force the windows phone users to use the
   * native application.
   * Note the optional supported parameter "require" in the above
   * configuration which will force the users to use the native
   * application.
   */
  appLink?: RuutuPlayerAppLink;

  /**
   * Native application name shown in application link
   */
  appName?: string;

  /**
   * Urls for downloading native application and opening video in the application. 
   * [NID] is replaced with media id in videoUrl.
   */
  appUrls?: object;

  /**
   * Enables content playback even though Adblock is in use.
   */
  alwaysAllowContentPlayback?: boolean;

  /**
   * Autohide controls duration in ms. Value 0 will disable autohiding.
   */
  autohide?: number;

  /**
   * When enabled and video is played to the end, the video gets started again from the beginning.
   */
  autoloop?: boolean;

  /**
   * Player changes video automatically when playback end is reached
   */
  autoNextInSequence?: boolean;

  /**
   * Indicates if the play should be started without user interaction.
   * If 'muted' play is started and volume is muted.
   */
  autoplay?: boolean | string;

  /**
   * Currently supported on supla players.
   */
  backgroundColor?: string;

  /**
   * Audiobook start position in seconds. Used for A/B testing.
   */
  bookStartPosition?: number;

  /**
   * Audiobook end position in seconds. Used for A/B testing.
   */
  bookEndPosition?: number;

  /**
   * element id where the player is to be embedded.
   */
  containerId: string;

  /**
   * Possibility to create control bar buttons. For example this is used
   * to display iframe overlay over the player Window. See the 4th
   * example code below for more details. Works only in Chrome
   * browser.
   */
  controlbarButton?: () => void;

  /**
   * Defines the Google Cast receiver-application, which player will
   * use when cast-icon is clicked. Cast-icon will not be visible on
   * players header-bar if chromecastReceiverId is not defined in
   * embed-parameters.
   * Chromecast Application IDs:
   *  - Ruutu = 5BE7096C
   *  - HS = BFA815A8
   *  - IS = 0F79E440
   *  - Aamulehti = 823DA4DB
   *  - Satakunnan Kansa = A7FDF673
   *  - Generic = C528B34
   */
  chromecastReceiverId?: number;

  /**
   * Dark mode. Currently supported on supla players.
   */
  darkMode?: boolean;

  /**
   * Takes into use Supla layout v2. See Supla theme section below
   * for instructions for the parameter. Theme can be reconfigured
   * during runtime with api function configureTheme.
   */
  dynamicTheme?: object;

  /**
   * Can change the environment of api:s player is calling. Possible
   * values are "dev", "sta" or "prod"
   */
  env?: string;

  /**
   * New version of layout for embedded supla players.
   */
  embedLayout?: boolean;

  /**
   * Player shows errors by default in its own container. If
   * errorCallback is set player won't show errors, but calls the
   * function with error-message (string) when error happens.
   */
  errorCallback?: (error: RuutuPlayerError) => void;

  /**
   * Forces player to load mxmcl as video. Can be used with
   * adsOnlyCallback to show video ads from audio mxmcl.
   */
  forceVideo?: boolean;

  /**
   * Passed to freewheel as part of siteSection
   */
  fw_site?: string;

  /**
   * defines the Google analytics ID for reporting, e.g. 'UA-3683704-15'
   */
  gaid?: string;

  /**
   * If set to true, the episode title is hidden from the header bar
   * when video is paused. This works only on video content but not
   * on audio content.
   */
  hideTitle?: boolean;

  /**
   * If set to true, and mxmcl has allow embed also set to true, embed code button is show on player header bar.
   */
  isEmbedAllowed?: boolean;

  /**
   * If set to true, player stays responsive but follows aspect ratio
   * given in width&height parameters.
   * 
   * Example for square videos: {
   *   height: 1,
   *   width: 1,
   *   responsive: true, // responsive also required to be set to true maintainAspectRatio: true,
   * }
   */
  maintainAspectRatio?: boolean;

  /**
   * If set the player height won't exceed this value in responsive mode. Useful in cases where the player area e.g. browser
   * window is wide but not so tall, in those cases without this
   * parameter being set the player height might exceed the
   * available height
   */
  maxHeight?: number;

  /**
   * Interval of midstream checks in seconds.
   */
  midstreamInterval?: number;

  /**
   * Possibility to set only the video id (Crossbow nid or Richie id) insted of the full MCC url.
   */
  mediaId?: number;

  /**
   * Set the url from where the player will fetch the media data.
   * The format of the data is JSON and it should be either MCC or Richie type.
   * If format of the data is Richie type, mediaType: "richie_podcast" is also required
   */
  mediaUrl?: string;

  /**
   * Indicate type of the media, used with mediaUrl. Possible values: richie_podcast
   */
  mediaType?: string;

  /**
   * If set the player calls the callback function when the content ends. 
   * The callback gets next video id as the first parameter
   */
  nextInSequence?: (videoId: number) => void;

  /**
   * If set to true the player does not use the iPad's native fullscreen mode. When in normal native fullscreen mode the player uses
   * ios native look and feel and no html content can be shown. e.g.
   * no 'mainos 1/2' text can be shown. Also ad-clickthrough clicks do
   * not work in native mode.
   * Enabling the non-native mode can cause problems for the player
   * if the embedding site uses non best practice styles to keep some
   * elements on top of everything. i.e. in some cases some site
   * elements might be on top of the player when changing to nonnative fullscreen state. Check your embed case in fullscreen and
   * change any infinite z-index elements to be more sane if using
   * this parameter.
   */
  nonNativeIpadFullscreen?: boolean;

  /**
   * Override ns_site that is parsed by default from the origin of the
   * page that embeds the player. Example
   * www.ruutu.fi -> ruutu-fi
   * www.foo.ruutu.fi -> ruutu-fi
   */
  ns_site?: string;

  /**
   * Automatically opens info dialog when player is started
   */
  openInfoDialog?: boolean;

  /**
   * 
   * Overrides the default adclick through handling functionality. 
   * By defining this function the application / site developer has the 
   * possibility to easily define how the adclick through urls are handled 
   * in the application.
   * 
   * If defined the player won't open the adclick through url to a new window. 
   * Instead the player will call the given function with the adclick through url.
   */
  onAdClick?: (url: string) => void;

  /**
   * If defined this function is called with an API object after the player is ready. 
   * An example of using onApi -parameter is in the playlist-example.
   */
  onApi?: (api: RuutuPlayerAPI) => void;

  /**
   * By default when the user clicks on the ad and the ad click
   * through url is opened. The ad is automatically paused.
   * If set to false, ads are not paused when the user clicks the ad.
   * Ads are usually paused automatically by the browser when
   * using a mobile browser, this behaviour can't be changed by the
   * player.
   * VPAID ads control the ad behaviour by themselves and they
   * usually pause the ad on clickthrough, and this behaviour can't be
   * changed by the player
   */
  pausePlaybackOnAdClickThrough?: boolean;

  /**
   * A list of media's that will be played. Will override next-insequence.
   * See playlist example for more information.
   */
  playlist?: number[];

  /**
   * Callback that will be called for next media to play.
   * See playlist example for more information.
   */
  nextMediaCallback?: () => void;

  /**
   * Start position in seconds.
   */
  position?: number;

  /**
   * Control which if any recommendations are shown after current video has ended.
   * See Recommendations for more information.
   */
  recommendations?: boolean | object;

  /**
   * When set to true, player will scale to the size of the parent
   * container while keeping 16:9 aspect ratio (for custom aspect
   * ratio see maintainAspectRatio). If set to false and height & width
   * defined, those are used.
   */
  responsive?: boolean;

  /**
   * When set to true, player will playback audiobook or podcast
   * episode sample and audio paywall.
   */
  sample?: boolean;

  /**
   * Which service should Gatling's /auth/identify/v2 use to determine
   * the current user's role within that service? Examples: "ruutu", "hs".
   */
  service?: string;

  /**
   * If the value is set to false, player social sharing feature is
   * disabled. By default the player has a Facebook and Twitter
   * share buttons which opens a share dialog. Also the video url is
   * visible in the dialog.
   * Social sharing feature is always enabled, if content owner is
   * nelonenmedia. Shared URL points to www.ruutu.fi.
   * Social sharing is not possible in live events.
   */
  sharing?: boolean;

  /**
   * Do not show any other controls than play button in the middle of the player area.
   */
  showOnlyPlayButton?: boolean;

  /**
   * Only for testing purposes. Do not ever use in production environments.
   * If set to true:
   * won't force the use of https with authenticated APIs,
   * forces player to use staging-gatling.nelonenmedia for all
   * Gatling API requests.
   */
  staging?: boolean;

  /**
   * If startDate is given, live streams try to start from the given time.
   * For example, the date could be start of a hockey match.
   */
  startDate?: Date;

  /**
   * If set to true, will use Supla audio player layout.
   */
  supla?: boolean;

  /**
   * Parts of the player will be colored with the theme color. Must be of format "#123456".
   */
  themeColor?: string;

  /**
   * If set to 'false' hotkeys are disabled.
   */
  useHotkeys?: boolean;

  /**
   * Set userRole that is otherwise fetched from the auth token or identify api.
   */
  userRole?: string;

  /**
   * The site where the player is embedded, e.g. RUUTU (See the
   * Ruutu.fi embed tool for a full list of vsites: www.ruutu.fi/videoembed)
   */
  vsite?: string;

  /**
   * Enables button for closing the player when set to true.
   */
  closeButton?: boolean;

  /**
   * Only for videoplayer. Enables button for opening the miniplayer
   */
  miniplayerButton?: boolean;
}

export type RuutuPlayerProps = Omit<RuutuPlayerInternalProps, 'containerId'>;

type HasRuutuPlayer = {
  RP: {
    embedPlayer: (params: RuutuPlayerInternalProps) => void;
  }
}

const hasRuutuPlayerLoaded = (window: Window): window is Window & HasRuutuPlayer => {
  return ('RP' in window);
}

const RuutuPlayerInternal = (props: RuutuPlayerProps) => {
  const playerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (playerRef.current && hasRuutuPlayerLoaded(window)) {
      window.RP.embedPlayer({ ...props, containerId: playerRef.current.id })
    }
  }, [props, playerRef]);

  return (
    <div id="mediaplayer" ref={playerRef} />
  );
}

export const RuutuPlayer = memo(RuutuPlayerInternal, (prevProps, newProps) => {
  return (JSON.stringify(prevProps) === JSON.stringify(newProps));
});