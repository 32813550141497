type Event = 'event'
type EventCategory = string;
type EventAction = string;
type EventLabel = string;
type EventNonInteraction = boolean;
type EventParams = [Event, EventCategory, EventAction, EventLabel, EventNonInteraction?];

type PageView = 'pageview';
type PageViewPageName = string;
type PageViewParams = [PageView, PageViewPageName];

type EventOrPageViewParams = EventParams | PageViewParams;

/**
 * Track Google analytics event
 * Params:
 * 0 = event/pageview
 * 1 = event category/url
 * 2 = event action
 * 3 = event label
 * 4 = nonInteraction true. Can be empty.
 * @param {EventOrPageViewParams} params
 */
export const googleAnalyticsTrack = (params: EventOrPageViewParams) => {
  if (!window.dataLayer) {
    console.log('Error, no dataLayer object available');
    return;
  }

  if (params[0] === 'event') {
    return window.dataLayer.push({
      event: 'eventInfo',
      eventCategory: `${params[1]}`,
      eventAction: `${params[2]}`,
      eventLabel: `${params[3]}`,
      eventNonInteraction: (!params[4]) ? 0 : 1,
    });
  }

  if (params[0] === 'pageview') {
    return window.dataLayer.push({
      'event': 'virtualPageview',
      'page': {
        'pageName': `${params[1]}`,
      },
    });
  }
};
