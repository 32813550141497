import { createContext } from 'react';
import { ApolloQueryResult } from '@apollo/client';

export type QueryResult<TData> = {
  data?: TData;
  error?: any;
  loading: boolean;
}

export type QueryContextValue<TData> = {
  queryData: QueryResult<TData>;
  refetch: () => Promise<ApolloQueryResult<TData>>;
}

export const QueryResultContext = createContext<QueryContextValue<any>>({
  queryData: { loading: true },
  refetch: () => Promise.resolve({} as ApolloQueryResult<any>),
});
