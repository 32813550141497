import { shuffle } from "lodash";
import { IS10KysymystaDate } from "../types/is10kysymysta-types";
import { Game } from "../types/game";
import { htmlspecialcharsDecode } from "../utils/htmlspecialchars-decode";
import { default as moment } from 'moment';
import { GameAnswer, GameQuestion } from "../types/game";

/**
   * Parse level to match with game model.
   * @param level Level data from API.
   * @returns {Object} Game model
   */
export const transformLevelToGame = (level: IS10KysymystaDate): Game => {
  const {
    levelid,
    date,
    questions,
    title,
    correctstats,
  } = level;

  // Decode questions & answers.
  const parsedQuestions: GameQuestion[] = questions.map((question, questionId) => {
    const decodedAnswer1 = htmlspecialcharsDecode(question.answer1, 'ENT_QUOTES');
    const decodedAnswer2 = htmlspecialcharsDecode(question.answer2, 'ENT_QUOTES');
    const decodedAnswer3 = htmlspecialcharsDecode(question.answer3, 'ENT_QUOTES');
    const decodedAnswer4 = htmlspecialcharsDecode(question.answer4, 'ENT_QUOTES');

    const answer1: GameAnswer = { answerId: 0, answer: decodedAnswer1, stats: question.answer1stats };
    const answer2: GameAnswer = { answerId: 1, answer: decodedAnswer2, stats: question.answer2stats };
    const answer3: GameAnswer = { answerId: 2, answer: decodedAnswer3, stats: question.answer3stats };
    const answer4: GameAnswer = { answerId: 3, answer: decodedAnswer4, stats: question.answer4stats };
    
    const shuffledAnswers = shuffle([answer1, answer2, answer3, answer4]);
    
    return {
      name: question.name,
      number: questionId + 1,
      question: htmlspecialcharsDecode(question.question, 'ENT_QUOTES'),
      answer1,
      answer2,
      answer3,
      answer4,
      shuffledAnswers,
    };
  });

  // Make game model data.
  return {
    correctstats,
    levelid,
    date: moment(date, 'yyyy-MM-DD'),
    id: levelid,
    title: htmlspecialcharsDecode(title, 'ENT_QUOTES'),
    questions: parsedQuestions,
  };
};