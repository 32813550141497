import { createContext } from 'react';
import { Game } from '../types/game';
import { Answers } from '../types/answers';
import { createEmptyAnswers } from '../utils/quiz-helpers';

export type GameContextData = {
  game?: Game;
  answers: Answers;
  playable: boolean;
  setGame: (level: Game) => void;
  setAnswers: (answers: Answers) => void;
  setPlayable: (playable: boolean) => void;
}

export const GameContext = createContext<GameContextData>({
  game: undefined,
  answers: createEmptyAnswers(),
  playable: false,
  setGame: () => void 0,
  setAnswers: () => void 0,
  setPlayable: () => void 0,
});
