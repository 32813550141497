import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { default as Settings } from '../config/settings';

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: createHttpLink({
    uri: Settings.GAMEDATA_URL,
    headers: {
      'x-api-key': String(Settings.GAMEDATA_APIKEY),
    },
    credentials: 'omit',
  }),
  name: 'IS10KysymystaApollo'
});