export type BreakNoticeProps = {
  enabled: boolean;
}

export const BreakNotice = ({ enabled }: BreakNoticeProps) => {
  const now = new Date();
  const begin = new Date('12/24/2021'); 
  const end = new Date('01/03/2022');

  if (enabled && begin <= now && now <= end) {
    return (
      <div className='break-notice'>
        <p>
          <strong>🎄 10 kysymystä on joulutauolla jouluaatosta tammikuulle.</strong>
          <br /> Seuraavat kysymykset tulevat pohdittaviksi 3. tammikuuta! 10 kysymystä toivottaa kaikille lukijoille hyvää joulua ja onnellista uutta vuotta!</p><p>Jos triviahammasta kolottaa, voit harjaannuttaa taitojasi <a href='https://alypaa.com/pelit/klassikko?utm_source=10kysymystafi&utm_medium=tiedote&utm_id=10kysymystafi-joulutiedote'>Älypää Klassikon</a> parissa.
        </p>
      </div>
    );
  }

  return <></>
}
