import { googleAnalyticsTrack } from "./google-analytics-track";
import { default as Settings } from '../config/settings';
import { SharedResult } from "../types/shared-result";

const createShareQuote = (result: SharedResult) => {
  const {
    date,
    howManyPlayed,
    howManyAreWorse,
    score,
  } = result;

  if (howManyPlayed >= 5 && howManyAreWorse > 33) {
    return `Tiesin paremmin kuin ${howManyAreWorse}% vastanneista ${date} IS 10 kysymyksessä.`;
  }

  if (howManyPlayed > 5) {
    return `Tiesin heikommin kuin ${(100 - howManyAreWorse)}% vastanneista ${date} IS 10 kysymyksessä.`;
  }

  return `Sain ${score} oikein ${date} IS 10 kysymyksestä.`;
}

const objectToParams = (input: Record<string, string>): string => {
  return new URLSearchParams(input).toString();
}

export const shareFacebook = (result: SharedResult) => {
  // Create share object
  const quote = createShareQuote(result);
  const obj = {
    display: 'popup',
    app_id: Settings.FACEBOOK_APP_ID,
    href: 'https://www.10kysymysta.fi',
    hashtag: '#10kysymysta',
    quote,
  };

  // Track GA event on score sharing.
  const params = objectToParams(obj);
  window.open(`https://www.facebook.com/dialog/share?${params}`);

  googleAnalyticsTrack(['event', 'Share', 'Score', 'Facebook']);
}

export const shareTwitter = (result: SharedResult) => {
  // Create share object
  const tweettext = createShareQuote(result);
  const obj = {
    url: 'https://www.10kysymysta.fi',
    via: 'iltasanomat',
    text: `${tweettext} #10kysymysta`,
  };

  // Track GA event on score sharing.
  const params = objectToParams(obj);
  window.open(`https://twitter.com/intent/tweet?${params}`);

  googleAnalyticsTrack(['event', 'Share', 'Score', 'Twitter']);
}

export const shareWhatsapp = (result: SharedResult) => {
  // Create share object
  const whatsapptext = createShareQuote(result);
  const obj = {
    text: `${whatsapptext} - https://www.10kysymysta.fi`,
  };

  // Track GA event on score sharing.
  const params = objectToParams(obj);
  window.open(`https://api.whatsapp.com/send?${params}`);

  googleAnalyticsTrack(['event', 'Share', 'Score', 'Whatsapp']);
}