import { ApolloProvider } from '@apollo/react-hooks';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { apolloClient } from './clients/apollo-client';
import { HelmetProvider } from 'react-helmet-async';

import { GoogleTagManager } from './components/google-tag-manager';
import { AdobeDTM } from './components/adobe-dtm';
import { Head } from './components/head';
import { Header } from './components/header';
import { Footer } from './components/footer';

import { QueryResultContextProvider } from './providers/query-result-context-provider';
import { GameContextProvider } from './providers/game-context-provider';
import { listNewestDatesQuery } from './queries/list-newest-dates';
import { AdStateContextProvider } from './providers/ad-state-context-provider';
import { UserContextProvider } from './providers/user-context-provider';

import { MainPage } from './pages/main-page';
import { QuizPage } from './pages/quiz-page';
import { EndPage } from './pages/end-page';

import settings from './config/settings';

const App = () => {
  return (
    <>
      <HelmetProvider>
        <AdobeDTM
          embed_url={settings.ADOBE_DTM_EMBED_URL}
          utag_data={{
            ad_site_name: '10kysymysta-fi',
            ad_disable_ads: 'false',
            ad_positions: ['top'],
            ad_page_category: 'ros',
            ad_page_subcategory: [],
            ad_tags: [],
            ad_keyvalues: {},
            page_type: 'ros'
          }} />
        <GoogleTagManager layer='dataLayer' id={settings.GTM_ID} />
        <ApolloProvider client={apolloClient}>
          <AdStateContextProvider>
            <QueryResultContextProvider query={listNewestDatesQuery} variables={{ maxDays: settings.MAX_DAYS }}>
              <UserContextProvider>
                <GameContextProvider>
                  <Head />
                  <div id="pagewrap" className='page'>
                    <Header />
                    <main>
                      <div id="content" className='content'>
                        <HashRouter>
                          <Routes>
                            <Route path='/' element={<MainPage />}/>
                            <Route path='/game/:levelid' element={<QuizPage />}/>
                            <Route path='/end' element={<EndPage />}/>
                          </Routes>
                        </HashRouter>
                      </div>
                    </main>
                    <Footer />
                  </div>
                </GameContextProvider>
              </UserContextProvider>
            </QueryResultContextProvider>
          </AdStateContextProvider>
        </ApolloProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
