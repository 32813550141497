import { useContext } from 'react';
import { QueryResult, QueryResultContext } from '../contexts/query-result-context';

export const useQueryResultContext = <TData = any>(): QueryResult<TData> => {
  const context = useContext(QueryResultContext);

  if (!context) {
    throw new Error(
      'useQueryResultContext must be used within a QueryResultContextProvider'
    );
  }

  return context.queryData;
};