import { useContext } from 'react';
import { UserContext, UserContextData } from '../contexts/user-context';

export const useUserContext = (): UserContextData => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error(
      'useUserContext must be used within a UserContextProvider'
    );
  }

  return context;
};