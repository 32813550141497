import { Helmet } from 'react-helmet-async';
import settings from '../config/settings';
import { default as AppleTouchIcon144x144 } from '../img/apple-touch-icon-144x144-precomposed.png';
import { default as AppleTouchIcon114x114 } from '../img/apple-touch-icon-114x114-precomposed.png';
import { default as AppleTouchIcon72x72 } from '../img/apple-touch-icon-72x72-precomposed.png';
import { default as AppleTouchIcon57x57 } from '../img/apple-touch-icon-57x57-precomposed.png';
import '../stylesheets/master.scss';

export const Head = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <title>IS 10 Kysymystä</title>
      <meta name="description" content="" />
      <meta name="HandheldFriendly" content="True" />
      <meta name="MobileOptimized" content="320" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      <meta name="viewport" content="user-scalable=0, width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      <link rel="dns-prefetch" href="//fonts.googleapis.com" />
      <link rel="dns-prefetch" href="//www.google-analytics.com" />
      <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&amp;subset=latin-ext" rel="stylesheet" />
      <link rel="apple-touch-icon" sizes="144x144" href={AppleTouchIcon144x144} />
      <link rel="apple-touch-icon" sizes="114x114" href={AppleTouchIcon114x114} />
      <link rel="apple-touch-icon" sizes="72x72" href={AppleTouchIcon72x72} />
      <link rel="apple-touch-icon" href={AppleTouchIcon57x57} />
      <script type="text/javascript" src={settings.RUUTU_PLAYER_URI} />
    </Helmet>
  );
};
