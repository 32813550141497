import { useNavigate } from 'react-router-dom';
import { transformLevelToGame } from '../transforms/level-to-game';
import { IS10KysymystaDate } from '../types/is10kysymysta-types';
import { ReactComponent as ArrowRight } from '../img/arrow-right.svg';
import { useGameContext } from '../hooks/use-game-context';
import { Game } from '../types/game';
import { useUserContext } from '../hooks/use-user-context';
import { countCorrectAnswers, createEmptyAnswers } from '../utils/quiz-helpers';
import { GameHistory } from '../types/game-history';
import { BreakNotice } from '../components/break-notice';
import { Reminder } from '../components/reminder';
import { migrateUserHistory } from '../transforms/migrate-history';

export type QuizMenuProps = {
  newestDates: IS10KysymystaDate[],
}

export const QuizMenu = ({ newestDates }: QuizMenuProps) => {
  const { setGame, setAnswers, setPlayable } = useGameContext();
  const { user, setUser } = useUserContext();
  const navigate = useNavigate();

  const openGame = (game: Game) => {
    setGame(game);
    setAnswers(createEmptyAnswers());
    setPlayable(true);
    navigate(`/game/${game.levelid}`);
  }

  const openStats = (game: Game, history: GameHistory) => {
    setGame(game);
    setAnswers(history.answers);
    setPlayable(false);
    navigate(`/end`);
  }

  const [updatedUser, migrated] = migrateUserHistory(user, newestDates);

  if (migrated) {
    console.log('Migrated user game history', updatedUser);
    setUser(updatedUser);
  }

  const renderGameButton = (entry: IS10KysymystaDate) => {
    const game = transformLevelToGame(entry);
    const history = user.history.find(({ level }) => level === game.levelid);

    if (history) {
      const prevScore = countCorrectAnswers(history.answers);

      return (
        <li className="button" key={entry.levelid}>
          <button
            className="button-inner menu-block menu-block-played"
            onClick={() => openStats(game, history)}>
              <div className="percent-green" style={{ width: `${prevScore}0%`}}></div>
              <span className="date">{game.date.format('DD.MM.yyyy')}</span>
              <span className="result">{prevScore}/{game.questions.length}</span>
          </button>
        </li>
      )
    }

    return (
      <li className="button" key={entry.levelid}>
        <button
          className="button-inner menu-block menu-block-playable"
          onClick={() => openGame(game)}>
          <span className="date">{game.date.format('DD.MM.yyyy')}</span>
          <ArrowRight />
        </button>
      </li>
    );
  }

  return (
    <>
      <BreakNotice enabled={false} />
      <Reminder />
      <ul className="quiz-menu">
        { newestDates.map(it => renderGameButton(it)) }
      </ul>
    </>
  );
}