import { ReactNode } from 'react';
import { OperationVariables, useQuery, DocumentNode, TypedDocumentNode } from '@apollo/client';
import { QueryResultContext } from '../contexts/query-result-context';

interface QueryResultProviderProps<TData = any, TVariables extends OperationVariables | undefined = object> {
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  variables?: TVariables;
  children: ReactNode;
}

export const QueryResultContextProvider = ({ query, variables, children } : QueryResultProviderProps) => {
  const { data, error, loading, refetch } = useQuery(query, { variables });
  const value = { queryData: { data, error, loading }, refetch };

  return (
    <QueryResultContext.Provider value={value}>{children}</QueryResultContext.Provider>
  );
};