import { useContext } from 'react';
import { GameContext, GameContextData } from '../contexts/game-context';

export const useGameContext = (): GameContextData => {
  const context = useContext(GameContext);

  if (!context) {
    throw new Error(
      'useGameContext must be used within a GameContextProvider'
    );
  }

  return context;
};