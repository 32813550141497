import Settings from '../config/settings';
import { GameHistory } from '../types/game-history';
import { IS10KysymystaDate } from '../types/is10kysymysta-types';
import { User } from '../types/user';

/** @deprecated */
type DeprecatedQuestion = {
    name: number;
    question: string;
    answer1: string;
    answer1stats: number;
    answer2: string;
    answer2stats: number;
    answer3: string;
    answer3stats: number;
    answer4: string;
    answer4stats: number;
    answers: string[];
    shuffledAnswers: string[];
};

/** @deprecated */
type DeprecatedHistory = {
  answerHistory: number[];
  date: string;
  questions: DeprecatedQuestion[];
  score: number;
};

/** @deprecated */
type DeprecatedData = {
  id: string;
  history?: DeprecatedHistory[];
};

/**
 * Helper to migrate history data from older format into newer one.
 * 
 * @param {User} user 
 * @param {IS10KysymystaDate[]} dates 
 * @returns {[User, boolean]}
 */
export const migrateUserHistory = (user: User, dates: IS10KysymystaDate[]): [User, boolean] => {
  if (dates.length === 0) {
    return [user, false];
  }

  const id = localStorage.getItem(`users-${Settings.LSB}`);
  if (id === null) {
    return [user, false];
  }

  const data = localStorage.getItem(`users-${Settings.LSB}-${id}`);
  if (data === null) {
    return [user, false];
  }

  const questionDataLookup = dates.reduce<Record<string, IS10KysymystaDate>>(
    (accum, current) => ({ ...accum, [current.date]: current }),
    {}
  );

  const historyExistsLookup = user.history.reduce<Record<string, boolean>>(
    (accum, current) => ({ ...accum, [current.level]: true }),
    {}
  );

  const deprecatedData: DeprecatedData = JSON.parse(data);
  const deprecatedHistory = (deprecatedData.history || [])
    .map(({ date, answerHistory  }) => {
      const question = questionDataLookup[date];
      if (!question) {
        return null;
      }

      const answers = answerHistory.reduce<Record<number, number>>(
        (accum, current, idx) => ({ ...accum, [idx]: current }),
        {}
      );

      const entry: GameHistory = { level: question.levelid, answers, migrated: true }
      return entry;
    })
    .filter(it => it !== null) as GameHistory[];

  let migrated = false;
  const migratedUser = { ...user };

  for (const history of deprecatedHistory) {
    if (!historyExistsLookup[history.level]) {
      migrated = true;
      migratedUser.history.push(history);
    }
  }

  return [migratedUser, migrated];
}