import { ReactNode, useState } from 'react';
import { GameContext } from '../contexts/game-context';
import { Game } from '../types/game';
import { createEmptyAnswers } from '../utils/quiz-helpers';
import { Answers } from '../types/answers';

export type GameContextProviderProps = {
  children: ReactNode;
}

export const GameContextProvider = ({ children }: GameContextProviderProps) => {
  const [game, setGame] = useState<Game | undefined>(undefined);
  const [answers, setAnswers] = useState<Answers>(createEmptyAnswers());
  const [playable, setPlayable] = useState<boolean>(false);
  const value = { game, answers, playable, setGame, setAnswers, setPlayable };

  return (
    <GameContext.Provider value={value}>
      {children}
    </GameContext.Provider>
  );
};