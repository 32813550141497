import { ReactElement, cloneElement, useState } from "react"
import { useDelay } from "../hooks/use-delay";
import classNames from "classnames";

export type FadeWrapperProps = {
  /**
   * Fade is enabled or not
   */
  enabled: boolean;
  
  /**
   * When does the fade in happen, defaults to 1000ms
   */
  startDelay?: number;

  /**
   * When does the fade out happen, defaults to 5700ms
   */
  visibleTime?: number; 

  /**
   * How long fade in takes, defaults to 100ms
   */
  fadeInDurationMs?: number;

  /**
   * How long fade out takes, defaults to 300ms
   */
  fadeOutDurationMs?: number;

  /**
   * Length of the total cycle
   */
  repeatAfter?: number; 

  /**
   * Visible class name
   */
  visibleClassName: string;
  
  /**
   * Opaque class name
   */
  opaqueClassName: string;

  /**
   * Child node to be rendered
   */
  children: ReactElement;
}

export const FadeWrapper = (props: FadeWrapperProps) => {
  const [ stage, setStage ] = useState(0);
  const [ extraClassNames, setExtraClassNames ] = useState<string[]>([]);

  const {
    enabled = false,
    startDelay = 0,
    visibleTime = 4600,
    fadeInDurationMs = 100,
    fadeOutDurationMs = 300,
    repeatAfter = 60000,
    visibleClassName,
    opaqueClassName,
    children,
  } = props;

  const nextStage = () => {
    setStage((stage + 1) % steps.length);
  }

  const steps = [
    {
      delay: startDelay,
      fn: () => { setExtraClassNames([visibleClassName]); nextStage(); }
    },
    {
      delay: fadeInDurationMs,
      fn: () => { setExtraClassNames([visibleClassName, opaqueClassName]); nextStage(); }
    },
    {
      delay: visibleTime,
      fn: () => { setExtraClassNames([visibleClassName]); nextStage(); }
    },
    {
      delay: fadeOutDurationMs,
      fn: () => { setExtraClassNames([]); nextStage(); }
    },
    {
      delay: repeatAfter - (startDelay + fadeInDurationMs + visibleTime + fadeOutDurationMs),
      fn: () => (void 0),
    }
  ];

  const step = steps[stage];
  const { className = '' } = children.props;

  useDelay(() => { if (enabled) { step.fn(); } }, step.delay);

  if (enabled) {
    return cloneElement(children, {
      ...children.props,
      className: classNames(className, ...extraClassNames),
    });
  }

  return children;
}