const {
  REACT_APP_ENV,
  REACT_APP_API_URL,
  REACT_APP_API_KEY,
  REACT_APP_MEDIA_URL,
  REACT_APP_RUUTU_PLAYER_URI,
  REACT_APP_GTM_ID,
  REACT_APP_ADOBE_DTM_EMBED_URL,
} = process.env;

// Defined via Webpack
const customerId = 'is10kysymysta';

const settings = {
  ENV: REACT_APP_ENV,
  DEFAULT_NICKNAME: 'Nimimerkki',
  FACEBOOK_APP_ID: '619422954870861',
  GOOGLE_ANALYTICS_ID: 'UA-189475-7',
  LSB: `${customerId}-20140507`,
  MAX_DAYS: 51,
  GAMEDATA_URL: REACT_APP_API_URL,
  GAMEDATA_APIKEY: REACT_APP_API_KEY,
  MEDIA_URL: REACT_APP_MEDIA_URL,
  RUUTU_PLAYER_URI: REACT_APP_RUUTU_PLAYER_URI,
  GTM_ID: String(REACT_APP_GTM_ID),
  ADOBE_DTM_EMBED_URL: String(REACT_APP_ADOBE_DTM_EMBED_URL)
};

export default settings;