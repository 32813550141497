import { useContext } from 'react';
import { AdStateContext, AdStateContextData } from '../contexts/ad-state-context';

export const useAdStateContext = (): AdStateContextData => {
  const context = useContext(AdStateContext);

  if (!context) {
    throw new Error(
      'useAdStateContext must be used within a AdStateContextProvider'
    );
  }

  return context;
};