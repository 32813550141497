import { useAdStateContext } from '../hooks/use-ad-state-context';
import { default as classNames } from 'classnames';

export const Footer = () => {
  const { adsState } = useAdStateContext();
  const adsVisible = (adsState !== 'ENDED');
  
  const showCookieConsent = () => {
    if (!window.utag) {
      console.log('Error, no utag object available');
      return;
    }
    
    window.utag.link({
      event_name: 'cmp_privacy_manager',
      event_action: 'show',
    });
  }

  return (
    <footer id="footer">
      <div className={classNames("footer-content", { 'st__hidden': adsVisible })}>
        <div>
          <div className="footer-view">
            <div>Palvelun tarjoavat</div>
            <a
              href="http://www.sanomagames.fi/?utm_source=is10kysymysta&utm_medium=is10kysymysta&utm_campaign=is10kysymysta"
              title="&copy; Sanoma Games"
              className="footer-logo footer-logo-sanomagames"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sanoma Games
            </a>
            <a
              href="https://www.is.fi"
              className="footer-logo footer-logo-iltasanomat"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ilta-Sanomat
            </a>
            <div className="footer-links">
              <a href="https://sanoma.fi/tietoa-meista/tietosuoja/tietosuojalauseke/" target="_blank" rel="noopener noreferrer">Tietosuojasi</a>
              <a href="https://sanoma.fi/tietoa-meista/tietosuoja/evastekaytannot/" target="_blank" rel="noopener noreferrer">Evästekäytännöt</a>
              <button id="cmpSettingsOpener" onClick={showCookieConsent}>Evästeasetukset</button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}