import { useMutation } from "@apollo/client";
import { saveDateAnswersQuery } from "../queries/save-date-answers";
import {
  IS10KysymystaDate,
  IS10KysymystaDateAnswerInput,
} from "../types/is10kysymysta-types";

type Variables = {
  input: IS10KysymystaDateAnswerInput;
}

export const useSaveDateAnswersMutation = () => {
  const [submit, { data, loading, error }] = useMutation<IS10KysymystaDate, Variables>(
    saveDateAnswersQuery
  );

  const saveDateAnswers = async (input: IS10KysymystaDateAnswerInput) => submit({ variables: { input }});
  return { saveDateAnswers, savedDate: data, saveDateLoading: loading, saveDateError: error };
}
