import { createContext } from 'react';
import { RuutuPlayerBasicState } from '../components/ruutu-player';

export type AdStateContextData = {
  adsState: RuutuPlayerBasicState;
  setAdsState: (adsState: RuutuPlayerBasicState) => void;
}

export const AdStateContext = createContext<AdStateContextData>({
  adsState: 'ENDED',
  setAdsState: () => void 0,
});
