import { Helmet } from "react-helmet-async";
import { DataLayer } from "../types/data-layer";
import { ObtainKeys } from "../types/obtain-keys";

type TDataLayer = ObtainKeys<Window, DataLayer | undefined>;

export type GoogleTagManagerProps = {
  id: string;
  layer?: TDataLayer;
};

export const GoogleTagManager = (props: GoogleTagManagerProps) => {
  const { id, layer = 'dataLayer' } = props;

  window[layer] = window[layer] || [];
  window[layer].push({
    'gtm.start': new Date().getTime(),
    'event': 'gtm.js'
  });

  const dataLayer = (layer !== 'dataLayer')
    ? `&l=${layer}`
    : '';

  return (
    <>
      <Helmet>
        <script
          src={`https://www.googletagmanager.com/gtm.js?id=${id}${dataLayer}`}
          async={true}>
        </script>
      </Helmet>
      <noscript>
        <iframe
          title='gtm'
          src={`https://www.googletagmanager.com/ns.html?id=${id}`}
          width={0}
          height={0}
          style={{
            display: 'none',
            visibility: 'hidden'
          }}>
        </iframe>
      </noscript>
    </>
  )
}